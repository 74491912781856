import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const buscarEspecialidade = async (payload, page) => {
    const { data } = await apiInstance.post(ENDPOINTS.BUSCAR_ESPECIALIDADE + `?page=${page}`, payload);
    return data
}

export const criarEspecialidade = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.CRIAR_ESPECIALIDADE, payload);
    return data
}

export const editarEspecialidade = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.EDITAR_ESPECIALIDADE + `/${id}`, payload);
    return data
}

export const exibirEspecialidade = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.EXIBIR_ESPECIALIDADE + `/${id}`);
    return data
}

export const excluirEspecialidade = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.EXCLUIR_ESPECIALIDADE + `/${id}`);
    return data
}

export const listarEspecialidade = async () => {
    const { data } = await apiInstance.post(ENDPOINTS.LISTAR_ESPECIALIDADE);
    return data
}